/* Hero */
.careers-hero {
  height: 547px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.careers-hero-bubble {
  position: absolute;
  right: 0;
  z-index: -1;
}

.careers-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.careers-hero-title {
  font-weight: 700;
  font-size: 100px;
  line-height: 110.3%;
  color: var(--prelim-color-off-black);
}

/* Mission */
.careers-mission {
  width: 100%;
  display: flex;
  align-items: center;
}

.careers-mission-lhs {
  flex: 1 1;
}

.careers-mission-rhs {
  flex: 1 1;
}

.careers-mission-image {
  width: 100%;
}

.careers-mission-copy {
  margin: 0 auto;
  max-width: 500px;
}

.careers-mission-title {
  color: var(--prelim-color-purple);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.careers-mission-subtitle {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 20px;
}

.careers-mission-description {
  color: var(--prelim-color-off-black);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 480px;
}


/* Values */
.careers-values {
  margin-top: 60px;
  margin-bottom: 60px;
}

.careers-values-content {
  margin-left: 97px;
  margin-right: 97px;
}

.careers-values-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--prelim-color-purple);
  text-transform: uppercase;
}

.careers-values-subtitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: var(--prelim-color-off-black);
}

.careers-values-item {
  border: 1px solid black;
  padding: 20px;
  border-radius: 16px;
  margin: 15px auto;
  background: white;
}

.careers-values-item-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--prelim-color-off-black);
  margin-bottom: 20px;
}

.careers-values-item-number {
  border: 1px solid var(--prelim-color-off-black);
  border-radius: 11px;
  height: 22px;
  width: 22px;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
}

/* Benefits */
.careers-benefits {
  background-color: var(--prelim-color-off-black);
  padding-top: 60px;
  padding-bottom: 60px;
}

.careers-benefits-content {
  margin-left: 97px;
  margin-right: 97px;
}


.careers-benefits-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--prelim-color-purple);
  text-transform: uppercase;
}

.careers-benefits-subtitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: white;
}

.careers-benefits-row-1{
  display: flex;
  margin: 40px 0;
  gap: 14px;
}

.careers-benefits-row-2{
  display: flex;
  margin: 40px 0;
  gap: 14px;
}

.careers-benefits-item {
  flex: 1 1;
}

.careers-benefits-item-title-container {
  display: flex;
}

.careers-benefits-item-title {
  color: white;
  margin-left: 5px;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
}

.careers-benefits-item-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-top: 10px;
}

/* Jobs */

.careers-jobs {
  padding-top: 60px;
  padding-bottom: 60px;
}

.careers-jobs-content {
  margin-left: 97px;
  margin-right: 97px;
}

.careers-jobs-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--prelim-color-purple);
  text-transform: uppercase;
}

.careers-jobs-subtitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: var(--prelim-color-off-black);
}

.careers-jobs-container {
  justify-content: space-between;
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.careers-jobs-team {
  background: #FFF;
  border: 1px solid #000;
  border-radius: 16px;
  overflow: hidden;
  width: 500px;
  height: 100%;
}

.careers-jobs-team-title {
  background: #EBF2B9;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  padding: 20px;
}

.careers-jobs-job {
  display: flex;
  justify-content: space-between;
}

.careers-jobs-job-title {
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  color: var(--prelim-color-off-black);
}

.careers-jobs-job-location {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-transform: uppercase;
}

.careers-jobs-job-link {
  background: var(--prelim-color-off-black);
  color: white;
  border-radius: 50px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  width: 104px;
  height: 37px;
  font-size: 14px;
  line-height: 37px;
}

.careers-jobs-job-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.careers-jobs-job-copy {
  padding: 20px;
}

@media (min-width: 1200px) {
  /* Hero */
  .careers-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  .careers-mission {
    width: 1140px;
    margin: 0 auto;
  }

  /* Values */
  .careers-values-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Benefits */
  .careers-benefits-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Jobs */
  .careers-jobs-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  /* Hero */

  .careers-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  .careers-mission {
    margin: 0 auto;
    width: 90%;
  }

  .careers-mission-lhs {
    display: none;
  }
  
  .careers-mission-description {
    width: 100%;
  }

  .careers-mission-copy {
    margin: 0;
    max-width: 100%;
  }

  /* Values */
  .careers-values-content {
    margin: 0 auto;
    width: 90%;
  }

  /* Benefits */
  .careers-benefits-content {
    margin: 0 auto;
    width: 90%;
  }

  .careers-benefits-row-1 {
    flex-direction: column;
  }

  .careers-benefits-row-2 {
    flex-direction: column;
  }

  /* Jobs */
  .careers-jobs-content {
    margin: 0 auto;
    width: 90%;
  }

  .careers-jobs-team {
    margin: 0 auto;
  }
}

@media (max-width: 743px) {
  /* Hero */

  .careers-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }


  .careers-hero-title {
    font-weight: 700;
    font-size: 44px;
  }


  .careers-mission {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .careers-mission-lhs {
    display: none;
  }

  .careers-mission-copy {
    margin: 0;
  }

  .careers-mission-description {
    width: 100%;
  }

  /* Values */
  .careers-values-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  /* Benefits */
  .careers-benefits-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .careers-benefits-row-1 {
    flex-direction: column;
  }

  .careers-benefits-row-2 {
    flex-direction: column;
  }

  /* Jobs */
  .careers-jobs-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .careers-jobs-team {
    margin: 0 auto;
  }

}
