.call-to-action {
  background-color: var(--prelim-color-off-black);
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.call-to-action-coa-image {
  position: absolute;
  right: 0;
  top: 5px;
}

.call-to-action-coa-title {
  color: white;
  font-size: 29px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 10px;
  z-index: 1;
}

.call-to-action-coa-subtitle {
  color: white;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
}

.call-to-action-coa-description {
  margin-top: 10px;
  color: white;
  font-size: 16px;
  line-height: 180%;
  z-index: 1;
}

.call-to-action-coa-button {
  appearance: none;
  background-color: var(--prelim-color-purple);
  height: 48px;
  width: 48px;
  border-radius: 10px;
  z-index: 1;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.call-to-action-coa-form {
  position: relative;
  width: 100%;
  width: 400px;
  margin-top: 20px;
}

.call-to-action-arrow path {
  fill: white;
}

.call-to-action-coa-email {
  appearance: none;
  outline: none;
  border: none;
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 4px;
  z-index: 1;
  display: block;
  position: relative;
}

.call-to-action-content {
  margin-left: 97px;
  margin-right: 97px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (min-width: 1200px) {
  .call-to-action-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (max-width: 743px) {
  .call-to-action-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .call-to-action-coa-form {
    width: 100%;
  }
}
