/* Hero */
.integrations-hero {
  height: 547px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.integrations-hero-bubble {
  position: absolute;
  right: 0;
  z-index: -1;
}

.integrations-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.integrations-hero-title {
  font-weight: 700;
  font-size: 100px;
  line-height: 110.3%;
  color: var(--prelim-color-off-black);
}

.integrations-hero-subtitle {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
  margin-left: 15px;
}

.integrations-hero-button {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  background: #6578ff;
  border-radius: 4px;
  height: 42px;
  line-height: 42px;
  width: 200px;
  cursor: pointer;
  color: white;
  text-align: center;
  display: block;
  margin-left: 15px;
}

/* Apps */
.integrations-apps {
  padding-top: 20px;
  padding-bottom: 150px;
}

.integrations-apps-content {
  margin-left: 97px;
  margin-right: 97px;
}

.integrations-apps-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: var(--prelim-color-off-black);
  margin: 15px;
}

.integrations-apps-category {
  text-align: center;
  color: #ff9d90;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.integrations-apps-description {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #67686a;
}

.integrations-apps-coa {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #67686a;
}

.integrations-apps-coa-link {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  text-decoration-line: underline;
  color: #6578ff;
}

.integrations-apps-list-container {
  margin-top: 50px;
}

.integrations-apps-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.integrations-app {
  background-color: white;
  border-radius: 10px;
  position: relative;
  background-size: cover;
  margin: 15px;
  width: 240px;
  padding: 40px;
  height: 210px;
  border: 1px solid;
  border-color: var(--pelim-color-off-black);
}

.integrations-app-image-container {
  height: 32px;
  display: block;
}

.integrations-app-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  margin-top: 33px;
  margin-bottom: 10px;
}

.integrations-app-description {
  color: #67686a;
  font-size: 15px;
  line-height: 26px;
}

.integrations-app-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 20px);
}

.integrations-app-link {
  color: #212121;
  font-size: 15px;
  line-height: 26px;
}

@media (min-width: 1200px) {
  /* Hero */
  .integrations-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Apps */
  .integrations-apps-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  .integrations-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  .integrations-hero-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
  }

  .integrations-apps-content {
    margin: 0 auto;
    width: 90%;
  }

  .integrations-app {
    width: 35%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 743px) {
  .integrations-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .integrations-hero-title {
    font-weight: 700;
    font-size: 44px;
  }

  .integrations-apps-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .integrations-app {
    width: 100%;
    margin: 20px auto;
  }
}
