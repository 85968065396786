.event_section_content {
  margin: 0 auto;
}

@media (min-width: 1250px) {
  .event_section_content {
    max-width: 1304px;
    margin: 0 auto;
  }

  .event_description_section_box {
    display: block;
  }
}

@media (max-width: 900px) {
  .event_section_content {
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* Event Description */

.event_description_section_box {
  margin-top: 90px;
  display: flex;
  margin-bottom: 90px;
}

.event_description_box_left {
  width: 50%;
}

.event_description_box_right {
  width: 50%;
}

.event_description_img {
  margin: 0 auto;
  display: block;
}

.event_description_section_category {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #ff9d90;
}

.event_description_section_title_1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #262729;
}

.event_description_section_title_2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #262729;
}

.event_description_section_date {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #67686a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.event_description_section_para {
  font-size: 16px;
  line-height: 30px;
  color: #67686a;
}

.event_description_section_button {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  background: #6578ff;
  border-radius: 4px;
  height: 42px;
  width: 130px;
  cursor: pointer;
  color: white;
  display: block;
  margin-top: 25px;
}

@media (max-width: 1250px) {
  .event_description_section_box {
    display: block;
  }

  .event_description_box_left {
    width: 100%;
  }

  .event_description_box_right {
    width: 80%;
    margin: 15px auto;
  }

  .event_description_section_category {
    text-align: center;
  }

  .event_description_section_title_1 {
    text-align: center;
  }

  .event_description_section_title_2 {
    text-align: center;
  }

  .event_description_section_date {
    text-align: center;
  }

  .event_description_section_para {
    text-align: center;
  }

  .event_description_section_button {
    margin: 15px auto;
  }
}

@media (max-width: 900px) {
  .event_description_img {
    width: 300px;
  }

  .event_description_box_right {
    width: 90%;
    margin: 15px auto;
  }
}

/* People Section */
.event_people_section {
  margin-top: 50px;
  margin-bottom: 77px;
}

.event_people_section_category {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ff9d90;
  margin-bottom: 10px;
}

.event_people_section_title {
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #262729;
  margin-bottom: 20px;
}

.event_people_section_para_1 {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #67686a;
}

.event_people_section_para_2 {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #67686a;
}

.event_people_section_people {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 35px;
}

.event_people_profile {
  width: 240px;
  height: 270px;
}

.event_people_profile_title {
  font-size: 15px;
  line-height: 30px;
  color: #212121;
}

.event_people_profile_name {
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  color: #212121;
}

.event_people_profile_container {
  position: relative;
  height: 180px;
}

.event_people_profile_img {
  position: absolute;
  z-index: -2;
  bottom: 0;
}

.event_people_profile_bg {
  background: #a8bffa;
  width: 171px;
  height: 171px;
  border-radius: 85px;
  position: absolute;
  z-index: -3;
}

.event_people_co_bg {
  background-color: white;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: -1;
  border-radius: 50px;
  bottom: 0;
  right: 0;
}

.event_people_co_img_container {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
}

.event_people_co_img {
  display: block;
}

@media (max-width: 1250px) {
  .event_people_section_people {
    max-width: 600px;
    flex-wrap: wrap;
  }
}

@media (max-width: 900px) {
  .event_people_section_people {
    max-width: 240px;
  }

  .event_people_profile {
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

/* Event Agenda */
.event_agenda_section {
  background-color: #f3f4f6;
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}

.event_agenda_section_title {
  color: #262729;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 40px;
}

.event_agenda_section_date {
  font-size: 15px;
  line-height: 30px;
  color: #212121;
  text-align: center;
  margin-bottom: 10px;
}

.event_agenda_section_item {
  color: #212121;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 30px;
}

.event_agenda_section_box {
  margin-top: 70px;
  margin-bottom: 70px;
}

.event_agenda_top_left_img {
  position: absolute;
  top: 0;
  left: 0;
}

.event_agenda_bottom_left_img {
  position: absolute;
  left: 0;
  bottom: -59px;
}

.event_agenda_top_right_img {
  position: absolute;
  top: -83px;
  right: 0;
}

.event_agenda_bottom_right_img {
  position: absolute;
  bottom: -110px;
  right: 0;
}

@media (max-width: 900px) {
  .event_agenda_top_left_img {
    width: 111px;
  }

  .event_agenda_top_right_img {
    width: 111px;
  }

  .event_agenda_bottom_left_img {
    width: 111px;
    bottom: -27px;
  }

  .event_agenda_bottom_right_img {
    width: 111px;
    bottom: -43px;
  }
}
