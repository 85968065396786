.footer-root {
  padding-top: 30px;
  background-color: var(--prelim-color-off-black);
  color: white;
}

.footer-content {
  margin-left: 97px;
  margin-right: 97px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer-list-header {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.footer-list-item {
  color: white;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  margin-top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.footer-list-address-title,
.footer-contact-title {
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  margin-top: 5px;
  display: block;
  text-decoration: none;
}

.footer-list-address-text,
.footer-contact-text {
  color: white;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px;
  margin-top: 5px;
  display: block;
  text-decoration: none;
}

.footer-list-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  gap: 20px;
}

.footer-bottom {
  border-top: 2px solid white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 80px;
}

.footer-bottom-left {
  color: white;
}

.footer-bottom-right-icon {
  color: white;
  margin: 0 8px;
}

.footer-company-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.footer-company-address-container,
.footer-company-contact-container {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.footer-logo {
  height: 24px;
  margin-bottom: 10px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

@media (min-width: 1200px) {
  .footer-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) and (min-width: 650px) {
  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-company-address-container,
  .footer-company-contact-container {
    flex-direction: row;
    gap: 3px;
  }

  .footer-company-information {
    gap: 0;
  }

  .footer-list-container {
    flex-flow: column wrap;
    height: 300px;
    justify-content: start;
    width: 100%;
  }
}

@media (max-width: 650px) {
  .footer-links {
    flex-direction: column;
    gap: 44px;
  }

  .footer-list-container {
    flex-direction: column;
  }
}
