.lightbox-overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 60%);
  z-index: 99999;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.lightbox-close-icon {
  position: absolute;
  right: 0;
}

.lightbox-close {
  position: relative;
  height: 24px;
  cursor: pointer;
  padding: 4px;
}

.lightbox-video {
  width: 770px;
  height: 433px;
}
