/* Hero */
.blog-post-hero {
  height: 547px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.blog-post-hero-bubble {
  position: absolute;
  left: 0;
  z-index: -1;
}

.blog-post-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.blog-post-hero-title {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 50px;
}

.blog-post-hero-subtitle {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
}

.blog-post-hero-article {
  display: flex;
  height: 300px;
}

.blog-post-hero-content-lhs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-post-hero-content-rhs {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.blog-post-hero-button {
  cursor: pointer;
  color: white;
  text-align: center;
  display: block;
  background: #212121;
  border-radius: 20px;
  width: 175px;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  text-decoration: none;
}

.blog-post-hero-image {
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid black;
}

.blog-post-hero-article-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-off-black);
}

.blog-post-hero-author-image {
  height: 60px;
  width: 60px;
}

.blog-post-hero-author {
  display: flex;
  margin-top: 30px;
}

.blog-post-hero-author-name {
  margin-left: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  color: var(--prelim-color-off-black);
}

.blog-post-hero-author-title {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--prelim-color-off-black);
  text-decoration: underline;
}

.blog-post-hero-article-placard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Article */
.blog-post-article-content {
  margin-left: 97px;
  margin-right: 97px;
}

.blog-post-article-body {
  max-width: 858px;
}

.blog-post-article-content-inner {
  display: flex;
  padding-bottom: 60px;
  gap: 20px;
}

.blog-post-article-rhs {
  position: relative;
}

.blog-post-article-rhs-inner {
  margin-top: 20px;
  position: sticky;
  top: 20px;
}

/* Subscribe */
.blog-post-subscribe {
  position: relative;
}

.blog-post-subscribe-form {
  background: #303030;
  color: white;
  padding: 20px;
  border-radius: 10px;
  top: 20px;
  width: 300px;
}

.blog-post-subscribe-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}

.blog-post-subscribe-description {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.blog-post-subscribe-email {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 28px;
  width: calc(100% - 60px);
}

.blog-post-subscribe-consent-label {
  margin-bottom: 20px;
}

.blog-post-subscribe-button {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  height: 42px;
  width: 100%;
  cursor: pointer;
  color: white;
  background: var(--prelim-color-purple);
  border-radius: 50px;
  text-transform: uppercase;
  margin-top: 20px;
}

.blog-post-subscribe-link {
  color: var(--prelim-color-purple);
  text-decoration: none;
}

/* Share */
.blog-post-share {
  margin-top: 20px;
  position: sticky;
}

.blog-post-share-title {
  color: var(--prelim-color-black);
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.blog-post-share-links {
  display: flex;
  justify-content: center;
}

.blog-post-share-link {
  margin: 15px;
  color: #212121;
}

/* Author */
.blog-post-author-image {
  width: 60px;
  height: 60px;
}

.blog-post-author-container {
  display: flex;
  align-items: flex-start;
}

.blog-post-author-intro {
  font-size: 29px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  margin-bottom: 30px;
}

.blog-post-author-left {
  display: flex;
  align-items: center;
}

.blog-post-author-description {
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-left: 50px;
  width: 800px;
}

.blog-post-author-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  margin-left: 8px;
}

.blog-post-author-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 8px;
  text-decoration: underline;
}

.blog-post-author {
  background-color: white;
  width: 100%;
  padding: 60px 0;
}

.blog-post-author-content {
  width: 1140px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  /* Hero */
  .blog-post-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Article */
  .blog-post-article-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  /* Hero */
  .blog-post-hero {
    height: unset;
    padding-top: 100px;
  }

  .blog-post-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .blog-post-hero-article {
    flex-direction: column;
    height: unset;
  }

  .blog-post-hero-content-rhs {
    display: none;
  }

  /* Article */
  .blog-post-article-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .blog-post-article-content-inner {
    flex-direction: column;
  }

  .blog-post-subscribe-form {
    width: unset;
  }

  .blog-post-article-body {
    max-width: 100%;
  }

  /* Author */
  .blog-post-author-content {
    flex-direction: column;
    width: calc(100% - 50px);
  }
}

@media (max-width: 743px) {
  /* Hero */
  .blog-post-hero {
    height: unset;
    padding-top: 100px;
  }

  .blog-post-hero-content {
    margin: 0 auto;
    width: calc(100% - 30px);
  }

  .blog-post-hero-article {
    flex-direction: column;
    height: unset;
  }

  .blog-post-hero-content-rhs {
    display: none;
  }

  /* Article */
  .blog-post-article-content {
    margin: 0 auto;
    width: calc(100% - 30px);
  }

  .blog-post-article-content-inner {
    flex-direction: column;
  }

  .blog-post-subscribe-form {
    width: unset;
  }

  /* Author */
  .blog-post-author-content {
    flex-direction: column;
    width: calc(100% - 50px);
  }

  .blog-post-author-container {
    flex-direction: column;
  }

  .blog-post-author-description {
    width: unset;
    margin-top: 40px;
    margin-left: unset;
  }
}
