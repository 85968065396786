/* Articles */
.news-callout {
  margin-top: 60px;
  margin-bottom: 60px;
}

.news-callout-content {
  margin-left: 97px;
  margin-right: 97px;
}

.news-callout-title {
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: var(--prelim-color-off-black);
  margin-bottom: 25px;
}

.news-callout-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.news-callout-article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 16px;
  background: white;
  padding: 10px;
}

.news-callout-article-image {
  width: 100%;
  border-radius: 10px;
}

.news-callout-article-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
  color: var(--prelim-color-off-black);
}

.news-callout-article-publication {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #757575;
}

.news-callout-article-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-decoration: none;
  cursor: pointer;
}

.news-callout-article-date {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #757575;
  margin-bottom: 12px;
}

@media (min-width: 1200px) {
  /* Articles */
  .news-callout-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  .news-callout-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: 0;
  } 

  .news-callout-article {
    width: calc(50% - 30px);
  }
}

@media (max-width: 744px) {
  .news-callout-content {
    width: calc(100% - 50px);
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .news-callout-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: 0;
  } 

  .news-callout-article {
    width: 100%;
  }

  .news-callout {
    margin: 0;
  }
}
