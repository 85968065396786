.header-root {
  height: 64px;
  width: 100%;
  position: absolute;
  border-bottom: 1px var(--prelim-color-off-black) solid;
  z-index: 1;
  background-color: #f8f6f0;
}

.header-container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.header-right-bar {
  display: flex;
}

.header-logo {
  line-height: 70px;
}

.header-nav-menu {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.header-nav-menu-item {
  display: inline-block;
  position: relative;
  text-align: center;
}

.header-nav-submenu {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  display: none;
  position: absolute;
  width: 260px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 1;
  background: #fff;
  border-radius: 16px;
  text-align: left;
}

.header-link {
  margin-left: 20px;
  margin-right: 20px;
  line-height: 64px;
  cursor: pointer;
  text-decoration: none;
  color: var(--prelim-color-off-black);
  font-size: 15px;
  font-weight: bold;
}

.header-nav-menu-item:hover .header-link {
  color: var(--prelim-color-off-black);
}

.header-nav-menu-item:hover .header-nav-submenu {
  display: block;
}

.header-nav-submenu-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-nav-submenu-link {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: var(--prelim-color-off-black);
}

.header-nav-submenu-text {
  margin-left: 16px;
}

.header-nav-submenu-text:hover {
  color: var(--prelim-color-off-black);
}

.header-nav-submenu-small {
  width: 160px;
}

.header-actions {
  line-height: 64px;
}

.header-button {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  height: 42px;
  width: 140px;
  cursor: pointer;
  color: white;
  background: var(--prelim-color-off-black);
  border-radius: 50px;
  text-transform: uppercase;
}

.header-hamburger-level {
  background: #fff;
  width: 18px;
  height: 2px;
  margin-top: 4px;
}

.header-hamburger-menu {
  display: none;
}

.header-menu {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.header-menu-link {
  text-decoration: none;
  color: var(--prelim-color-off-black);
  font-weight: bold;
  font-size: 23px;
  text-align: center;
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 64px;
}

/* Hamburger */

.header-hamburger-box {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-hamburger {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.header-hamburger-bar {
  width: 25px;
  display: block;
  border-bottom: 2px solid;
  margin-bottom: 5px;
  color: var(--prelim-color-off-black);
  cursor: pointer;
}

/* Mobile Menu */

.header-mobile {
  display: none;
}

.header-mobile-nav-menu {
  position: fixed;
  left: 0;
  background-color: white;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: scroll;
}

.header-mobile-nav-menu-item {
  text-align: center;
  margin-bottom: 20px;
}

.header-mobile-nav-submenu-item {
  text-align: center;
}

.header-mobile-nav-submenu {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.header-mobile-link {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  color: #262729;
}

.header-mobile-nav-submenu-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-mobile-nav-submenu-link {
  text-decoration: none;
  color: #6578ff;
  font-size: 18px;
}

@media (min-width: 1024px) {
  .header-root {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
  }
}

@media (max-width: 1024px) {
  .header-root {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
  }

  .header-nav {
    display: none;
  }

  .header-logo {
    margin: 0 auto;
  }

  .header-mobile {
    display: block;
  }

  .header-button {
    width: 200px;
  }
}
