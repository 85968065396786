/* Hero */
.consumer-account-opening-hero {
  width: 100%;
  min-height: calc(100vh - 160px);
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.consumer-account-opening-hero-container {
  display: flex;
  margin-top: 50px;
}

.consumer-account-opening-hero-bubble {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0%);
}

.consumer-account-opening-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.consumer-account-opening-hero-video {
  border-radius: 20px;
  background-color: transparent;
  width: 550px;
  height: 303px;
  position: relative;
  cursor: pointer;
}

.consumer-account-opening-hero-video-thumbnail {
  border-radius: 20px;
  width: 550px;
  height: 303px;
  position: relative;
  cursor: pointer;
}

.consumer-account-opening-hero-container-lhs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.consumer-account-opening-hero-title {
  color: var(--prelm-color-off-black);
  padding-top: 160px;
  font-weight: 700;
  font-size: 100px;
  line-height: 110%;
}

.consumer-account-opening-hero-subtitle {
  color: var(--prelm-color-off-black);
  display: block;
  max-width: 80%;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
}

.consumer-account-opening-hero-description {
  width: 90%;
  margin-top: 15px;
  color: var(--prelm-color-off-black);
  font-size: 16px;
}

.consumer-account-opening-hero-buttons {
  display: flex;
  align-items: center;
}

.consumer-account-opening-hero-form {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.consumer-account-opening-hero-email-input {
  appearance: none;
  outline: none;
  border: none;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  display: block;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  width: 300px;
}

.consumer-account-opening-hero-button {
  appearance: none;
  background-color: var(--prelim-color-off-black);
  border-radius: 0 10px 10px 0;
  display: block;
  cursor: pointer;
  line-height: 22px;
  height: 62px;
  width: 200px;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}

.consumer-account-opening-hero-video-button {
  position: absolute;
  background: rgb(168 191 250 / 50%);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.consumer-account-opening-hero-video-button-inner {
  background: #a8bffa;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  position: absolute;
}

.consumer-account-opening-hero-button-image {
  position: absolute;
  height: 26px;
  width: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 10px);
}

/* Customers */

.consumer-account-opening-customers {
  background-color: white;
}

.consumer-account-opening-customers-content {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.consumer-account-opening-customers-list::-webkit-scrollbar {
  display: none;
}

.consumer-account-opening-customers-list {
  display: flex;
  flex-grow: 1;
  animation: scroll-customers 240s linear infinite;

  --webkit-animation: scroll-customers 240s linear infinite;
}

.consumer-account-opening-customers-logo-img {
  align-self: center;
  margin: 0 auto;
  display: block;
  height: 85px;
}

.consumer-account-opening-customer-header {
  font-size: 24px;
  color: #262729;
  text-align: center;
}

.consumer-account-opening-customer-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.consumer-account-opening-customer {
  display: inline-block;
  border-radius: 16px;
  margin: 10px 20px;
  height: 85px;
}

.consumer-account-opening-customer-image {
  height: 85px;
}

.consumer-account-opening-customer-logo-img {
  align-self: center;
  margin: 0 auto;
  display: block;
  height: 85px;
}

/* End to End */

.consumer-account-opening-end-to-end-content {
  margin-left: 97px;
  margin-right: 97px;
}

.consumer-account-opening-end-to-end-content-bottom {
  display: flex;
}

.consumer-account-opening-end-to-end-lhs {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.consumer-account-opening-end-to-end-rhs {
  flex: 1 1;
  height: 546px;
  background: #ebf2b9;
}

.consumer-account-opening-end-to-end-category {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 10px;
}

.consumer-account-opening-end-to-end-title {
  color: var(--prelim-color-off-black);
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  margin: 140px 0;
}

.consumer-account-opening-end-to-end-copy {
  max-width: 490px;
}

.consumer-account-opening-end-to-end-images {
  display: flex;
  margin-left: 10%;
}

.consumer-account-opening-end-to-end-image-left {
  margin-top: -100px;
  width: 231px;
  height: 452px;
}

.consumer-account-opening-end-to-end-image-right {
  width: 231px;
  height: 452px;
}

.consumer-account-opening-end-to-end-description {
  color: var(--prelim-color-off-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* WorkWith */

.consumer-account-opening-work-with {
  background-color: var(--prelim-color-off-black);
}

.consumer-account-opening-work-with-content {
  margin-left: 97px;
}

.consumer-account-opening-work-with-content-bottom {
  margin: 60px 0;
  display: flex;
  padding-bottom: 50px;
  justify-content: space-between;
}

.consumer-account-opening-work-with-lhs {
  flex: 1 1;
}

.consumer-account-opening-work-with-rhs {
  display: flex;
  align-items: center;
}

.consumer-account-opening-work-with-category {
  color: white;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 20px;
}

.consumer-account-opening-work-with-content-inner {
  max-width: 518px;
}

.consumer-account-opening-work-with-title {
  color: white;
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  padding: 140px 0;
}

.consumer-account-opening-work-with-mobile-tdb {
  height: 480px;
}

.consumer-account-opening-work-with-mobile-frb {
  height: 480px;
}

.consumer-account-opening-work-with-description {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.consumer-account-opening-work-with-image {
  width: 550px;
}

/* Omnichannel */

.consumer-account-opening-omnichannel-content {
  margin-left: 97px;
  margin-right: 97px;
}

.consumer-account-opening-omnichannel-content-top {
  display: flex;
}

.consumer-account-opening-omnichannel-lhs {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}

.consumer-account-opening-omnichannel-rhs {
  flex: 1 1;
  margin: 20px;
}

.consumer-account-opening-omnichannel-category {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 10px;
}

.consumer-account-opening-omnichannel-title {
  color: var(--prelim-color-off-black);
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  padding: 140px 0;
}

.consumer-account-opening-omnichannel-copy {
  margin: 146px auto;
  max-width: 460px;
}

.consumer-account-opening-omnichannel-description {
  color: var(--prelim-color-off-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.consumer-account-opening-omnichannel-content-bottom-container {
  display: flex;
}

.consumer-account-opening-omnichannel-content-bottom {
  margin: 0 auto;
  overflow: hidden;
  height: 500px;
  display: flex;
}

.consumer-account-opening-omnichannel-tablet {
  width: 667px;
  display: block;
}

.consumer-account-opening-omnichannel-mobile {
  height: 460px;
  margin: 20px;
  margin-left: 40px;
}

.consumer-account-opening-omnichannel-desktop {
  height: 500px;
  margin: 20px;
}

/* Solutions */
.consumer-account-opening-solutions-content {
  display: flex;
  height: 400px;
}

.consumer-account-opening-solutions-business-account {
  flex: 1 1;
  background: #c5d2e7;
  display: flex;
  align-items: center;
}

.consumer-account-opening-solutions-business-account-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  color: var(--prelim-color-off-black);
}

.consumer-account-opening-solutions-consumer-account {
  flex: 1 1;
  background: #eed9a2;
  display: flex;
  align-items: center;
}

.consumer-account-opening-solutions-consumer-account-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  color: var(--prelim-color-off-black);
}

.consumer-account-opening-solutions-content-inner {
  width: 100%;
}

.consumer-account-opening-solutions-link {
  text-align: center;
  display: block;
  text-decoration: none;
  color: var(--prelim-color-purple);
  cursor: pointer;
}

/* Testimonial */

.consumer-account-opening-testimonial {
  margin-top: 80px;
  margin-bottom: 80px;
}

.consumer-account-opening-testimonial-container {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
}

.consumer-account-opening-testimonial-quote {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: var(--prelim-color-off-black);
}

.consumer-account-opening-testimonial-rhs-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.consumer-account-opening-testimonial-quotee {
  display: flex;
  gap: 10px;
}

.consumer-account-opening-testimonial-quotee-name {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.consumer-account-opening-testimonial-quotee-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.consumer-account-opening-testimonial-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-decoration: none;
  display: flex;
}

/* Analysis */
.consumer-account-opening-analysis {
  background: var(--prelim-color-off-black);
}

.consumer-account-opening-analysis-content {
  margin-left: 97px;
  margin-right: 97px;
}

.consumer-account-opening-analysis-title {
  color: white;
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  padding: 140px 0;
}

.consumer-account-opening-analysis-image-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.consumer-account-opening-analysis-image {
  width: 667px;
}

.consumer-account-opening-analysis-content-lhs {
  flex: 1 1;
}

.consumer-account-opening-analysis-content-copy {
  margin: 146px auto;
  max-width: 460px;
}

.consumer-account-opening-analysis-content-rhs {
  flex: 1 1;
  margin: 20px;
}

.consumer-account-opening-analysis-content-title {
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.consumer-account-opening-analysis-content-description {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.consumer-account-opening-analysis-content-bottom {
  display: flex;
}

@media (min-width: 1200px) {
  /* Hero */
  .consumer-account-opening-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Customers */

  /* End to end */
  .consumer-account-opening-end-to-end-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Worh With */
  .consumer-account-opening-work-with-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Omnichannel */
  .consumer-account-opening-omnichannel-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Analysis */
  .consumer-account-opening-analysis-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  /* Hero */
  .consumer-account-opening-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  .consumer-account-opening-hero-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
  }

  .consumer-account-opening-hero-container {
    flex-direction: column;
  }

  .consumer-account-opening-hero-subtitle {
    width: 100%;
  }

  .consumer-account-opening-hero-description {
    width: 100%;
  }

  .consumer-account-opening-hero-form-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .consumer-account-opening-hero-video {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .consumer-account-opening-hero-video-thumbnail {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .consumer-account-opening-hero {
    overflow: hidden;
  }

  /* Customers */

  .consumer-account-opening-customer-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .consumer-account-opening-customer {
    width: 40%;
    margin-top: 40px;
  }

  /* End to End */
  .consumer-account-opening-end-to-end-content {
    margin: 0 auto;
    width: 90%;
  }

  .consumer-account-opening-end-to-end-title {
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    padding: 80px 0;
    margin: 0;
  }

  .consumer-account-opening-end-to-end-lhs {
    justify-content: center;
    margin-bottom: 150px;
    padding-top: 100px;
  }

  .consumer-account-opening-end-to-end-images {
    justify-content: center;
    margin-left: 0;
  }

  .consumer-account-opening-end-to-end-content-bottom {
    flex-direction: column;
    background: #ebf2b9;
    padding-bottom: 100px;
  }

  /* Work With */
  .consumer-account-opening-work-with-content {
    margin: 0 auto;
    width: 90%;
  }

  .consumer-account-opening-work-with-title {
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    padding: 80px 0;
  }

  .consumer-account-opening-work-with-content-bottom {
    flex-direction: column;
    align-items: center;
  }

  .consumer-account-opening-work-with-rhs {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  /* Omnichannel */
  .consumer-account-opening-omnichannel-content {
    margin: 0 auto;
    width: 90%;
  }

  .consumer-account-opening-omnichannel-title {
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    padding: 80px 0;
  }

  .consumer-account-opening-omnichannel-content-top {
    flex-direction: column;
  }

  .consumer-account-opening-omnichannel-tablet {
    position: unset;
  }

  .consumer-account-opening-omnichannel-lhs {
    margin-top: 60px;
  }

  .consumer-account-opening-omnichannel-copy {
    margin: 60px auto;
  }

  .consumer-account-opening-omnichannel-mobile {
    display: none;
  }

  .consumer-account-opening-omnichannel-desktop {
    max-width: 100%;
  }

  /* Tailored */
  .consumer-account-opening-tailored-content {
    width: 90%;
    margin: 0 auto;
  }

  .consumer-account-opening-tailored-title {
    flex-direction: column;
    padding: 80px 0;
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
  }

  .consumer-account-opening-tailored-button {
    margin-top: 20px;
  }

  .consumer-account-opening-tailored-content-inner-bottom {
    flex-direction: column;
  }

  .consumer-account-opening-tailored-lhs {
    justify-content: center;
    padding-bottom: 60px;
  }

  .consumer-account-opening-tailored-image {
    justify-content: center;
  }

  /* Analysis */
  .consumer-account-opening-analysis-content {
    width: 90%;
    margin: 0 auto;
  }

  .consumer-account-opening-analysis-title {
    flex-direction: column;
    padding: 80px 0;
    margin: 0;
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
  }

  .consumer-account-opening-analysis-content-bottom {
    flex-direction: column;
  }

  .consumer-account-opening-analysis-content-lhs {
    display: flex;
    justify-content: center;
  }

  .consumer-account-opening-analysis-content-rhs {
    max-width: 400px;
    width: unset;
    margin: 0 auto;
  }

  .consumer-account-opening-compliant-copy {
    margin: 60px auto;
  }
}

/* Mobile */

@media (max-width: 743px) {
  /* Hero */
  .consumer-account-opening-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-hero-title {
    font-weight: 700;
    font-size: 40px;
  }

  .consumer-account-opening-hero-container {
    flex-direction: column;
  }

  .consumer-account-opening-hero-subtitle {
    width: 100%;
    font-weight: 700;
    font-size: 29px;
  }

  .consumer-account-opening-hero-description {
    width: 100%;
  }

  .consumer-account-opening-hero-form-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .consumer-account-opening-hero-video {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .consumer-account-opening-hero-video-thumbnail {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .consumer-account-opening-hero {
    overflow: hidden;
  }

  .consumer-account-opening-hero-email-input {
    width: unset;
  }

  .consumer-account-opening-hero-button {
    width: unset;
    line-height: unset;
  }

  /* Customers */
  .consumer-account-opening-customers-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-customer-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .consumer-account-opening-customer {
    margin: 40px auto;
  }

  /* Solutions */
  .consumer-account-opening-solutions-content {
    display: block;
    height: unset;
  }

  .consumer-account-opening-solutions-business-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .consumer-account-opening-solutions-consumer-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /* End to End */
  .consumer-account-opening-end-to-end-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-end-to-end-title {
    margin: 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding: 40px 0;
  }

  .consumer-account-opening-end-to-end-lhs {
    justify-content: center;
    margin-bottom: 150px;
  }

  .consumer-account-opening-end-to-end-images {
    justify-content: center;
    margin-left: 0;
  }

  .consumer-account-opening-end-to-end-content-bottom {
    flex-direction: column;
    padding-bottom: 100px;
  }

  .consumer-account-opening-end-to-end-image-right {
    display: none;
  }

  /* Work With */
  .consumer-account-opening-work-with-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-work-with-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding: 40px 0;
  }

  .consumer-account-opening-work-with-content-bottom {
    flex-direction: column;
    align-items: center;
  }

  .consumer-account-opening-work-with-rhs {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .consumer-account-opening-work-with-image {
    max-width: 90%;
  }

  /* Omnichannel */
  .consumer-account-opening-omnichannel-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-omnichannel-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding: 40px 0;
  }

  .consumer-account-opening-omnichannel-content-top {
    flex-direction: column;
  }

  .consumer-account-opening-omnichannel-tablet {
    position: unset;
    max-width: 100%;
  }

  .consumer-account-opening-omnichannel-lhs {
    margin-top: 60px;
  }

  .consumer-account-opening-omnichannel-copy {
    margin: 20px auto;
  }

  .consumer-account-opening-omnichannel-mobile {
    display: none;
  }

  .consumer-account-opening-omnichannel-desktop {
    max-width: 100%;
  }

  .consumer-account-opening-omnichannel-content-bottom {
    display: none;
  }

  /* Analysis */
  .consumer-account-opening-analysis-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .consumer-account-opening-analysis-image {
    max-width: 90%;
  }

  .consumer-account-opening-analysis-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding: 40px 0;
  }

  .consumer-account-opening-analysis-content-bottom {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .consumer-account-opening-analysis-content-lhs {
    display: flex;
    justify-content: center;
  }

  .consumer-account-opening-analysis-content-rhs {
    width: unset;
    margin: 0 auto;
  }

  .consumer-account-opening-compliant-copy {
    margin: 60px auto;
  }
}
