li {
  color: #000;
  font-size: 12pt;
}

p {
  margin: 0;
  color: #000;
  font-size: 12pt;
}

h1 {
  padding-top: 16pt;
  color: #000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 0;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: center;
}

h2 {
  padding-top: 14pt;
  color: #000;
  font-size: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 0;
  color: #000;
  font-size: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 0;
  color: #000;
  font-size: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 0;
  color: #000;
  font-size: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 8pt;
  color: #000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

ol.lst-kix_list_3-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}

ol.lst-kix_list_3-3 {
  list-style-type: none;
}

ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

ol.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}

ul.lst-kix_list_1-0 {
  list-style-type: none;
}

ol.lst-kix_list_3-0 {
  list-style-type: none;
}

ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

.lst-kix_list_3-0 > li::before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}

ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

.lst-kix_list_3-1 > li::before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) "."
    counter(lst-ctn-kix_list_3-1, decimal) " ";
}

.lst-kix_list_3-2 > li::before {
  content: "(" counter(lst-ctn-kix_list_3-2, lower-latin) ") ";
}

ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

.lst-kix_list_3-5 > li::before {
  content: "" counter(lst-ctn-kix_list_3-5, decimal) ". ";
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li::before {
  content: "(" counter(lst-ctn-kix_list_3-4, upper-latin) ") ";
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li::before {
  content: "(" counter(lst-ctn-kix_list_3-3, lower-roman) ") ";
}

ol.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ol.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_3-8 > li::before {
  content: "" counter(lst-ctn-kix_list_3-8, decimal) ". ";
}

.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}

.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}

.lst-kix_list_3-6 > li::before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}

.lst-kix_list_3-7 > li::before {
  content: "" counter(lst-ctn-kix_list_3-7, decimal) ". ";
}

ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}

ol.lst-kix_list_2-2 {
  list-style-type: none;
}

ol.lst-kix_list_2-3 {
  list-style-type: none;
}

ol.lst-kix_list_2-4 {
  list-style-type: none;
}

ol.lst-kix_list_2-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-0 {
  list-style-type: none;
}

ol.lst-kix_list_2-1 {
  list-style-type: none;
}

.lst-kix_list_4-8 > li::before {
  content: "\0025a0   ";
}

.lst-kix_list_4-7 > li::before {
  content: "\0025cb   ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

ol.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ol.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}

.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}

.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}

.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}

.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}

ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}

ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

.lst-kix_list_2-6 > li::before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
}

.lst-kix_list_2-7 > li::before {
  content: "" counter(lst-ctn-kix_list_2-7, decimal) ". ";
}

.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}

.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}

.lst-kix_list_2-4 > li::before {
  content: "(" counter(lst-ctn-kix_list_2-4, upper-latin) ") ";
}

.lst-kix_list_2-5 > li::before {
  content: "" counter(lst-ctn-kix_list_2-5, decimal) ". ";
}

.lst-kix_list_2-8 > li::before {
  content: "" counter(lst-ctn-kix_list_2-8, decimal) ". ";
}

ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}

ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

.lst-kix_list_4-0 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}

.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}

.lst-kix_list_4-1 > li::before {
  content: "\0025cb   ";
}

.lst-kix_list_4-4 > li::before {
  content: "\0025cb   ";
}

ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

.lst-kix_list_4-3 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_4-5 > li::before {
  content: "\0025a0   ";
}

.lst-kix_list_4-2 > li::before {
  content: "\0025a0   ";
}

.lst-kix_list_4-6 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}

.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}

.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}

ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

.lst-kix_list_1-0 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_1-1 > li::before {
  content: "\0025cb   ";
}

.lst-kix_list_1-2 > li::before {
  content: "\0025a0   ";
}

ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}

.lst-kix_list_1-3 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_1-4 > li::before {
  content: "\0025cb   ";
}

ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

.lst-kix_list_1-7 > li::before {
  content: "\0025cb   ";
}

ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

.lst-kix_list_1-5 > li::before {
  content: "\0025a0   ";
}

.lst-kix_list_1-6 > li::before {
  content: "\0025cf   ";
}

.lst-kix_list_2-0 > li::before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
}

.lst-kix_list_2-1 > li::before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
    counter(lst-ctn-kix_list_2-1, decimal) " ";
}

ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

.lst-kix_list_1-8 > li::before {
  content: "\0025a0   ";
}

.lst-kix_list_2-2 > li::before {
  content: "(" counter(lst-ctn-kix_list_2-2, lower-latin) ") ";
}

.lst-kix_list_2-3 > li::before {
  content: "(" counter(lst-ctn-kix_list_2-3, lower-roman) ") ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c6 {
  margin-left: 0;
  padding-top: 12pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 0;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c0 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 0.1pt;
}

.c15 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c13 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
}

.c12 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c20 {
  padding-top: 0;
  padding-bottom: 15pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
  border-bottom: 1px solid black;
  margin-bottom: 20pt;
}

.c4 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c17 {
  padding-top: 6pt;
  padding-bottom: 0;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c2 {
  text-decoration-skip-ink: none;
  text-decoration-skip: none;
  color: #000;
  text-decoration: underline;
}

.c9 {
  margin-left: 0;
  list-style-position: inside;
  text-indent: 18pt;
}

.c14 {
  padding: 72pt 54.1pt 72pt 85.3pt;
}

.c10 {
  margin-left: 0;
  list-style-position: inside;
  text-indent: 45pt;
}

.c11 {
  margin-left: 0;
  list-style-position: inside;
  text-indent: 43pt;
}

.c3 {
  padding: 0;
  margin: 0;
}

.c8 {
  font-style: italic;
  font-weight: 700;
}

.c22 {
  font-size: 12pt;
}

.c7 {
  margin-right: 0.1pt;
}

.c5 {
  color: #000;
}

.c18 {
  height: 12pt;
}

.c16 {
  font-size: 9pt;
}

.c21 {
  font-style: italic;
}

.c1 {
  font-size: 11pt;
}

.title {
  padding-top: 24pt;
  color: #000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c19 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
  font-size: 36px;
  line-height: 65.37px;
}
