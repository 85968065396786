/* Banner */

.banner-banner {
  background-color: #212121;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
}

.banner-banner-img {
  height: 50px;
  position: absolute;
}

.banner-banner-copy {
  line-height: 50px;
  color: white;
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}

.banner-banner-copy:hover {
  color: #eaeae8;
}

.banner-banner-center {
  display: flex;
  align-items: center;
}

.banner-banner-icon path {
  fill: white;
}

.banner-banner-icon {
  margin-right: 10px;
}

.banner-banner-close-icon path {
  fill: white;
}

.banner-banner-close-icon {
  cursor: pointer;
}
@media (max-width: 900px) {
  .banner-banner-copy {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 30px;
  }

  .banner-banner-icon {
    display: none;
  }
}
