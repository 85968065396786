html body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  background-color: #f8f6f1;
  color: var(--prelim-color-off-black);
}

button {
  border: none;
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0.1em;
}

input {
  font-family: "Open Sans", sans-serif !important;
}

:root {
  --prelim-color-off-black: #212121;
  --prelim-color-purple: #4e63f8;
  --prelim-color-purple-dark: #8c81e3;
  --prelim-color-lilac: #cb81f5;
  --prelim-color-lilac-dark: #b28bc8;
  --prelim-color-pink: #f091b2;
  --prelim-color-pink-dark: #d994ac;
  --prelim-color-coral: #ff8777;
  --prelim-color-coral-dark: #ea8376;
  --prelim-color-yellow: #ffce44;
  --prelim-color-yellow-dark: #f1b713;
  --prelim-media-tv: 1300px;
  --prelim-media-desktop: 1026px;
  --prelim-media-tablet: 771px;
  --prelim-media-mobile: 481px;
}
