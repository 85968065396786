/* Hero */
.landing-hero {
  width: 100%;
  min-height: calc(100vh - 160px);
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.landing-hero-container {
  display: flex;
  margin-top: 50px;
}

.landing-hero-bubble {
  position: absolute;
  z-index: -1;
}

.landing-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.landing-hero-video {
  border-radius: 20px;
  background-color: white;
  width: 550px;
  height: 303px;
  position: relative;
  cursor: pointer;
  max-width: 600px;
  margin: 0 auto;
}

.landing-hero-container-lhs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-hero-title {
  color: var(--prelm-color-off-black);
  padding-top: 20vh;
  font-weight: 600;
  font-size: 72px;
  line-height: 110%;
}

.landing-hero-subtitle {
  color: var(--prelm-color-off-black);
  display: block;
  max-width: 80%;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 20px;
}

.landing-hero-description {
  width: 90%;
  color: var(--prelm-color-off-black);
  font-size: 36px;
  margin-bottom: 50px;
}

.landing-hero-buttons {
  display: flex;
  align-items: center;
}

.landing-hero-form {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-hero-email-input {
  appearance: none;
  outline: none;
  border: none;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  display: block;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  width: 300px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.landing-hero-button {
  appearance: none;
  background-color: var(--prelim-color-off-black);
  border-radius: 0 10px 10px 0;
  display: block;
  cursor: pointer;
  line-height: 22px;
  height: 62px;
  width: 200px;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}

.landing-hero-video-button {
  position: absolute;
  background: rgb(168 191 250 / 50%);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.landing-hero-video-button-inner {
  background: #a8bffa;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  position: absolute;
}

.landing-hero-button-image {
  position: absolute;
  height: 26px;
  width: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 10px);
}

/* Customers */
@keyframes scroll-customers {
  0% {
    --webkit-transform: translate(0);
    --moz-transform: translate(0);
    --ms-transform: translate(0);

    transform: translate(0);
  }

  100% {
    --webkit-transform: translate(-100%);
    --moz-transform: translate(-100%);
    --ms-transform: translate(-100%);

    transform: translate(-100%);
  }
}

.landing-customers {
  background-color: white;
}

.landing-customers-title {
  color: var(--prelim-color-off-black);
  font-weight: 600;
  font-size: 29px;
  line-height: 76px;
  padding: 12px 0;
  text-align: center;
}

.landing-customers-content {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.landing-customers-list::-webkit-scrollbar {
  display: none;
}

.landing-customers-list {
  display: flex;
  flex-grow: 1;
  animation: scroll-customers 240s linear infinite;

  --webkit-animation: scroll-customers 240s linear infinite;
}

.landing-customer {
  height: 85px;
  margin: 10px 40px;
}

.landing-customers-image {
  height: 85px;
}

.landing-customers-logo-img {
  align-self: center;
  margin: 0 auto;
  display: block;
  height: 85px;
}

/* Omnichannel */

.landing-omnichannel-content {
  margin-left: 97px;
  margin-right: 97px;
  padding-top: 68px;
  padding-bottom: 68px;
}

.landing-omnichannel-accordion-item {
  border: 1px solid #212121;
  background: #fff;
  padding: 20px 31px;
  padding-right: 10px;
  overflow: auto;
  width: 380px;
  position: relative;
}

.landing-omnichannel-accordion-sidebar {
  background: #4e63f8;
  width: 15px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  flex-shrink: 0;
}

.landing-omnichannel-accordion-header {
  background: #fff;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.landing-omnichannel-accordion-title {
  color: #212121;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  float: left;
  width: 300px;
}

.landing-omnichannel-accordion-title:hover {
  color: #4e63f8;
  font-weight: 700;
}

.landing-omnichannel-accordion-title-expanded {
  color: #4e63f8;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  float: left;
  width: 300px;
}

.landing-omnichannel-accordion-content {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 300px;
}

.landing-omnichannel-accordion-button {
  float: right;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 12px;
}

.landing-omnichannel-content-top {
  display: flex;
}

.landing-omnichannel-lhs {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: left;
  display: flex;
}

.landing-omnichannel-rhs {
  flex: 1 1;
  margin: 20px;
}

.landing-omnichannel-category {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 10px;
}

.landing-omnichannel-title {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 29px;
  line-height: 76px;
}

.landing-omnichannel-copy {
  margin: 146px auto;
  max-width: 460px;
}

.landing-omnichannel-description {
  color: var(--prelim-color-off-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.landing-omnichannel-content-container {
  background: #ebf2b9;
}

.landing-omnichannel-content-bottom-container {
  display: flex;
}

.landing-omnichannel-content-bottom {
  margin: 0 auto;
  overflow: hidden;
  height: 500px;
  display: flex;
}

.landing-tailored-omnichannel-img-mobile {
  display: none;
}

.landing-omnichannel-tablet {
  width: 667px;
  display: block;
  position: absolute;
  right: 0;
}

.landing-omnichannel-mobile {
  height: 460px;
  margin: 20px;
  margin-left: 40px;
}

.landing-omnichannel-desktop {
  height: 500px;
  margin: 20px;
}

/* Tailored */

.landing-tailored-content {
  margin-left: 97px;
  margin-right: 97px;
}

.landing-tailored-button {
  background: var(--prelim-color-off-black);
  color: white;
  height: 75px;
  width: 250px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 75px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.landing-tailored-content-bottom {
  background-color: var(--prelim-color-off-black);
}

.landing-tailored-content-inner-bottom {
  padding: 60px 0;
  display: flex;
}

.landing-tailored-lhs {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.landing-tailored-rhs {
  flex: 1 1;
  margin: 20px;
}

.landing-tailored-category {
  color: white;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 20px;
}

.landing-tailored-content-inner {
  max-width: 518px;
}

.landing-tailored-title {
  color: var(--prelim-color-off-black);
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  padding: 140px 0;
  display: flex;
  justify-content: space-between;
}

.landing-tailored-image {
  display: flex;
  justify-content: flex-end;
}

.landing-tailored-omnichannel-img {
  height: 480px;
}

.landing-tailored-omnichannel-img-automate {
  height: 480px;
  margin-left: -20px;
}

.landing-tailored-omnichannel-img-mobile-automate {
  display: none;
}

.landing-tailored-mobile-tdb {
  height: 480px;
}

.landing-tailored-mobile-frb {
  height: 480px;
}

.landing-tailored-description {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* Compliant */

.landing-compliant-content {
  margin-left: 97px;
  margin-right: 97px;
}

.landing-compliant-content-bottom {
  display: flex;
  justify-content: space-between;
}

.landing-compliant-lhs {
  flex: 1 1;
  height: 547px;
}

.landing-compliant-rhs {
  width: 400px;
}

.landing-compliant-category {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: var(--prelim-color-off-black);
  margin-bottom: 20px;
}

.landing-compliant-copy {
  margin: 90px auto;
  max-width: 460px;
}

.landing-compliant-title {
  color: var(--prelim-color-off-black);
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  margin: 140px 0;
}

.landing-compliant-description {
  color: var(--prelim-color-off-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.landing-compliant-preview-container {
  position: relative;
  height: 500px;
  background: #ff9789;
  width: 700px;
}

.landing-compliant-alloy-img {
  width: 371.59px;
  position: absolute;
  left: 0;
  top: 0;
}

.landing-compliant-plaid-img {
  width: 371.59px;
  position: absolute;
  right: 0;
  top: 0;
}

.landing-compliant-persona-img {
  width: 371.59px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.landing-compliant-middesk-img {
  width: 371.59px;
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Solutions */
.landing-solutions-content {
  display: flex;
  height: 400px;
}

.landing-solutions-business-account {
  flex: 1 1;
  background: #6578ff;
  display: flex;
  align-items: center;
}

.landing-solutions-business-account-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  color: white;
}

.landing-solutions-consumer-account {
  flex: 1 1;
  background: #2a4b93;
  display: flex;
  align-items: center;
}

.landing-solutions-consumer-account-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  color: white;
}

.landing-solutions-content-inner {
  width: 100%;
}

.landing-solutions-link {
  text-align: center;
  display: block;
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin-top: 15px;
}

/* Testimonial */

.landing-testimonial {
  padding-top: 80px;
  padding-bottom: 80px;
  background: white;
}

.landing-testimonial-content {
  display: flex;
  margin-left: 97px;
  margin-right: 97px;
}

.landing-testimonial-quote {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: var(--prelim-color-off-black);
}

.landing-testimonial-rhs {
  width: 70%;
  margin: 0 auto;
}

.landing-testimonial-rhs-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.landing-testimonial-quotee {
  display: flex;
  gap: 10px;
}

.landing-testimonial-quotee-name {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.landing-testimonial-quotee-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.landing-testimonial-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-decoration: none;
  display: flex;
}

@media (min-width: 1200px) {
  /* Hero */
  .landing-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Omnichannel */
  .landing-omnichannel-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Testimonial */
  .landing-testimonial-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Tailored */
  .landing-tailored-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Compliant */
  .landing-compliant-content {
    width: 1140px;
    margin: 0 auto;
  }
}

/* Tablet */

@media (min-width: 744px) and (max-width: 1199px) {
  /* Hero */
  .landing-hero {
    height: unset;
  }

  .landing-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  .landing-hero-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
  }

  .landing-hero-container {
    flex-direction: column;
  }

  .landing-hero-subtitle {
    width: 100%;
  }

  .landing-hero-description {
    width: 100%;
  }

  .landing-hero-form-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .landing-hero-video {
    width: 100%;
    margin-top: 20px;
  }

  /* Customers */

  .landing-customers-list {
    justify-content: space-between;
  }

  .landing-customer {
    width: 40%;
    margin-top: 40px;
  }

  /* Solutions */
  .landing-solutions-content {
    display: block;
  }

  .landing-solutions-business-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .landing-solutions-consumer-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /* Omnichannel */
  .landing-omnichannel-content {
    margin: 0 auto;
    width: 90%;
  }

  .landing-omnichannel-title {
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    padding: 80px 0;
  }

  .landing-omnichannel-content-top {
    flex-direction: column;
  }

  .landing-omnichannel-tablet {
    position: unset;
  }

  .landing-omnichannel-lhs {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }

  .landing-omnichannel-copy {
    margin: 60px auto;
  }

  .landing-omnichannel-mobile {
    display: none;
  }

  .landing-omnichannel-desktop {
    max-width: 100%;
  }

  .landing-tailored-omnichannel-img {
    display: none;
  }

  .landing-tailored-omnichannel-img-mobile {
    width: 100%;
    display: block;
  }

  .landing-tailored-omnichannel-img-mobile-automate {
    width: 423px;
    margin-left: -11px;
    display: block;
  }

  .landing-tailored-omnichannel-img-automate {
    display: none;
  }

  /* Tailored */
  .landing-tailored-content {
    width: 90%;
    margin: 0 auto;
  }

  .landing-tailored-title {
    flex-direction: column;
    padding: 80px 0;
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
  }

  .landing-tailored-button {
    margin-top: 20px;
  }

  .landing-tailored-content-inner-bottom {
    flex-direction: column;
  }

  .landing-tailored-lhs {
    justify-content: center;
    padding-bottom: 60px;
  }

  .landing-tailored-image {
    justify-content: center;
  }

  /* Compliant */
  .landing-compliant-content {
    width: 90%;
    margin: 0 auto;
  }

  .landing-compliant-title {
    flex-direction: column;
    padding: 80px 0;
    margin: 0;
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
  }

  .landing-compliant-content-bottom {
    flex-direction: column;
  }

  .landing-omnichannel-rhs {
    max-width: 400px;
    width: unset;
    margin: 0 auto;
  }

  .landing-compliant-lhs {
    display: flex;
    justify-content: center;
  }

  .landing-compliant-rhs {
    max-width: 400px;
    width: unset;
    margin: 0 auto;
  }

  .landing-compliant-copy {
    margin: 60px auto;
  }
}

/* Mobile */

@media (max-width: 743px) {
  /* Hero */
  .landing-hero {
    height: unset;
  }

  .landing-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .landing-hero-title {
    font-weight: 700;
    font-size: 44px;
  }

  .landing-hero-container {
    flex-direction: column;
  }

  .landing-hero-subtitle {
    width: 100%;
    font-weight: 700;
    font-size: 29px;
  }

  .landing-hero-description {
    width: 100%;
  }

  .landing-hero-form-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .landing-hero-video {
    width: 100%;
    margin-top: 20px;
  }

  .landing-hero-email-input {
    width: unset;
  }

  .landing-hero-button {
    width: unset;
    line-height: unset;
  }

  /* Customers */

  .landing-customers-list {
    justify-content: space-around;
  }

  .landing-customer {
    margin: 40px;
  }

  /* Solutions */
  .landing-solutions-content {
    display: block;
    height: unset;
  }

  .landing-solutions-business-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .landing-solutions-consumer-account {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /* Omnichannel */
  .landing-omnichannel-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .landing-omnichannel-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding: 20px 0;
  }

  .landing-omnichannel-content-top {
    flex-direction: column;
  }

  .landing-omnichannel-tablet {
    position: unset;
    max-width: 100%;
  }

  .landing-omnichannel-lhs {
    margin-top: 60px;
  }

  .landing-omnichannel-copy {
    margin: 20px auto;
  }

  .landing-omnichannel-mobile {
    display: none;
  }

  .landing-omnichannel-desktop {
    max-width: 100%;
  }

  .landing-omnichannel-content-bottom {
    display: none;
  }

  .landing-tailored-omnichannel-img {
    display: none;
  }

  .landing-tailored-omnichannel-img-mobile {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -20px;
    display: block;
  }

  .landing-tailored-omnichannel-img-mobile-automate {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -20px;
    display: block;
  }

  .landing-tailored-omnichannel-img-automate {
    display: none;
  }

  .landing-omnichannel-accordion {
    width: calc(100%);
  }

  .landing-omnichannel-accordion-item {
    width: auto;
  }

  .landing-omnichannel-accordion-content {
    width: auto;
  }

  /* Tailored */
  .landing-tailored-content {
    width: calc(100% - 50px);
    margin: 0 auto;
  }

  .landing-tailored-title {
    flex-direction: column;
    padding: 40px 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
  }

  .landing-tailored-button {
    margin-top: 20px;
  }

  .landing-tailored-content-inner-bottom {
    flex-direction: column;
  }

  .landing-tailored-lhs {
    justify-content: center;
    padding-bottom: 60px;
  }

  .landing-tailored-image {
    justify-content: center;
    display: none;
    flex-direction: column;
    gap: 20px;
  }

  /* Compliant */
  .landing-compliant-content {
    width: calc(100% - 50px);
    margin: 0 auto;
  }

  .landing-compliant-title {
    flex-direction: column;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    margin: 40px 0;
  }

  .landing-compliant-content-bottom {
    flex-direction: column;
  }

  .landing-compliant-lhs {
    display: flex;
    justify-content: center;
  }

  .landing-compliant-rhs {
    max-width: 400px;
    width: unset;
    margin: 0 auto;
  }

  .landing-compliant-copy {
    margin: 60px auto;
  }

  .landing-compliant-alloy-img {
    width: 200px;
  }

  .landing-compliant-plaid-img {
    width: 200px;
  }

  .landing-compliant-middesk-img {
    width: 200px;
  }

  .landing-compliant-persona-img {
    width: 200px;
  }

  .landing-compliant-preview-container {
    height: 300px;
    max-width: 400px;
  }
}
