/* Hero */
.about-hero {
  height: 547px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.about-hero-bubble {
  position: absolute;
  right: 0;
  z-index: -1;
}

.about-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.about-hero-title {
  font-weight: 700;
  font-size: 100px;
  line-height: 110.3%;
  color: var(--prelim-color-off-black);
}

/* Founders */
.about-founders {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

.about-founders-content {
  margin-left: 97px;
  margin-right: 97px;
  width: 100%;
}

.about-founders-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--prelim-color-purple);
}

.about-founders-subtitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: var(--prelim-color-off-black);;
  margin-bottom: 35px;
}

.about-founders-founders {
  display: flex;
  justify-content: space-between;
}

.about-founders-founder {
  width: 444px;
  height: 568px;
  background: white;
  border-radius: 16px;
  padding: 20px;
}

.about-founders-founder-name {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-top: 20px;
  color: #000;
}

.about-founders-founder-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--prelim-color-off-black);
  margin-top: 20px;
}

/* Team */
.about-team {
  margin-top: 80px;
  margin-bottom: 80px;
}

.about-team-content {
  margin-left: 97px;
  margin-right: 97px;
}

.about-team-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--prelim-color-purple);
  text-transform: uppercase;
}

.about-team-subtitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: var(--prelim-color-off-black);
  margin-top: 10px;
}

.about-team-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 35px;
}

/* Collage Formal */
.about-collage-formal {
  width: 100%;
  overflow: clip;
  height: 186px;
}

/* Collage Informal */
.about-collage-informal {
  width: 100%;
  height: 186px;
  overflow: clip;
}

@media (min-width: 1200px) {
  /* Hero */
  .about-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Founders */
  .about-founders-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Team */
  .about-team-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  /* Hero */
  .about-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  /* Founders */
  .about-founders-content {
    margin: 0 auto;
    width: 90%;
  }

  /* Team */
  .about-team-content {
    margin: 0 auto;
    width: 90%;
  }

  .about-founders-founders {
    flex-direction: column;
    padding-bottom: 0;
  }

  .about-founders-founder {
    margin: 20px auto;
  }

}

@media (max-width: 743px) {
  /* Hero */
  .about-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .about-hero-title {
    font-weight: 700;
    font-size: 44px;
  }

  /* Founders */
  .about-founders-content {
    margin: 0 auto;
    width: calc(100% - 50px);

  }

  .about-founders-founder {
    margin: 20px auto;
    width: calc(100% - 40px);
  }

  .about-founders-founder-img-inner {
    width: 100%;
  }

  /* Team */
  .about-team-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .about-founders-founders {
    flex-direction: column;
    padding-bottom: 0;
  }

}
