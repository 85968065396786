/* Hero */
.signup-hero {
  height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.signup-hero-bubble {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0%);
}

.signup-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.signup-hero-content-title {
  font-size: 30px;
  line-height: 110.3%;
  color: var(--prelim-color-off-black);
  margin-bottom: 15px;
  text-align: center;
}

.signup-hero-form {
  background: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  width: 500px;
  padding: 42px 48px;
  margin: 30px auto;
}

.signup-hero-form-label {
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
  color: var(--prelim-color-off-black);
}

.signup-hero-button {
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  display: block;
  background: #6578ff;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  height: 42px;
  width: 100%;
  color: white;
  cursor: pointer;
  margin-top: 12px;
}

.signup-hero-input {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 12px 18px;
  font-size: 16px;
  color: #212121;
  outline: none;
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
}

select[class="signup-hero-input"]:invalid {
  color: #6a6a6a;
}

.signup-hero-input-error {
  border: 1px solid #c24030;
  background-color: #ffece9;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 12px 18px;
  font-size: 16px;
  color: #212121;
  outline: none;
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
}

select[class="signup-hero-input-error"]:invalid {
  color: #c24030;
}

.signup-hero-input-error::placeholder {
  color: #c24030;
}

@media (min-width: 1200px) {
  /* Hero */
  .signup-hero-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  .signup-hero-content {
    margin: 0 auto;
    width: 90%;
  }
}

@media (max-width: 743px) {
  .signup-hero-content {
    margin: 0 auto;
    width: calc(100% - 20px);
  }

  .signup-hero-form {
    width: unset;
  }
}
