.not-found-hero {
  height: 496px;
  background-image: url("../img/not_found_hero.png");
  background-size: cover;
  display: flex;
  align-items: center;
}

.not-found-content {
  width: 40%;
  background: #FFF;
  border-radius: 16px;
  margin: 0 auto;
  padding: 30px;
}

.not-found-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--prelim-color-purple);
}

.not-found-subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--prelim-color-off-black);
}

.not-found-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--prelim-color-off-black);
}
