/* Testimonial */

.testimonial {
  padding-top: 80px;
  padding-bottom: 80px;
  background: white;
}

.testimonial-content {
  display: flex;
  margin-left: 97px;
  margin-right: 97px;
}

.testimonial-quote {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: var(--prelim-color-off-black);
}

.testimonial-lhs {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}

.testimonial-logo-img {
  height: 88px;
}

.testimonial-rhs {
  width: 70%;
}

.testimonial-rhs-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.testimonial-quotee {
  display: flex;
  gap: 10px;
}

.testimonial-quotee-name {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.testimonial-quotee-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.testimonial-quotee-img {
  height: 44px;
  width: 44px;
}

.testimonial-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-decoration: none;
  display: flex;
}


@media (min-width: 1200px) {
  .testimonial-content {
    width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 744px) and (max-width: 1199px) {
  .testimonial-content {
    width: 90%;
    margin: 0 auto;
  }

}

@media (max-width: 743px) {
  .testimonial-content {
    width: calc(100% - 50px);
    margin: 0 auto;
    flex-direction: column;
  }

  .testimonial-lhs {
    margin: 0 auto;  
    width: 85%;
  }

  .testimonial-rhs {
    width: 85%;
    margin: 0 auto;  
  }
}
