/* Hero */
.news-hero {
  height: 547px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.news-hero-bubble {
  position: absolute;
  left: 0;
  z-index: -1;
}

.news-hero-content {
  margin-left: 97px;
  margin-right: 97px;
}

.news-hero-title {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 30px;
}

.news-hero-subtitle {
  color: var(--prelim-color-off-black);
  font-weight: 700;
  font-size: 19px;
  line-height: 39px;
}

.news-hero-article {
  display: flex;
  height: 300px;
}

.news-hero-content-lhs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-hero-content-rhs {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.news-hero-button {
  cursor: pointer;
  color: white;
  text-align: center;
  display: block;
  background: #212121;
  border-radius: 20px;
  width: 175px;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  text-decoration: none;
}

.news-hero-image {
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid;
}

/* Articles */
.news-articles {
  margin-top: 60px;
  margin-bottom: 60px;
}

.news-articles-content {
  margin-left: 97px;
  margin-right: 97px;
}

.news-articles-title {
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: var(--prelim-color-off-black);
  margin-bottom: 25px;
}

.news-articles-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.news-articles-article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 16px;
  background: white;
  padding: 10px;
}

.news-articles-article-image {
  width: 100%;
  border-radius: 10px;
}

.news-articles-article-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
  color: var(--prelim-color-off-black);
}

.news-articles-article-publication {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #757575;
}

.news-articles-article-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--prelim-color-purple);
  text-decoration: none;
  cursor: pointer;
}

.news-articles-article-date {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #757575;
  margin-bottom: 12px;
}

@media (min-width: 1200px) {
  /* Hero */
  .news-hero-content {
    width: 1140px;
    margin: 0 auto;
  }

  /* Articles */
  .news-articles-content {
    width: 1140px;
    margin: 0 auto;
  }
}
@media (min-width: 744px) and (max-width: 1199px) {
  .news-hero {
    padding-top: 50px;
    display: block;
  }

  .news-hero-content {
    margin: 0 auto;
    width: 90%;
  }

  .news-hero-article {
    display: block;
    height: initial;
  }

  .news-hero-content-lhs {
    margin: 20px auto;
    gap: 20px;
    padding-top: 40px;
    width: 600px;
  }

  .news-hero-content-rhs {
    margin: 0 auto;
    flex-direction: unset;
    justify-content: center;
  }

  .news-hero-subtitle {
    display: none;
  }

  .news-hero-article-date {
    display: none;
  }

  .news-hero-image{
    width: 600px;
  }

  .news-articles-content {
    max-width: 600px;
    margin: 0 auto;
  }

  .news-articles-list {
    grid-template-columns: 100%;
  }
}

@media (max-width: 743px) {
  .news-hero {
    padding-top: 50px;
    display: block;
  }

  .news-hero-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .news-hero-article {
    display: block;
    height: initial;
  }

  .news-hero-content-lhs {
    margin: 20px auto;
    gap: 20px;
    padding-top: 40px;
    max-width: 100%;
  }

  .news-hero-content-rhs {
    margin: 0 auto;
    flex-direction: unset;
    justify-content: center;
  }

  .news-hero-subtitle {
    display: none;
  }

  .news-hero-article-date {
    display: none;
  }

  .news-hero-image{
    width: 600px;
  }

  .news-articles-content {
    margin: 0 auto;
    width: calc(100% - 50px);
  }

  .news-articles-list {
    grid-template-columns: 100%;
  }
}
